import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-financial-add-account-dialog',
  templateUrl: './financial-add-account-dialog.component.html'
})
export class FinancialAddAccountDialogComponent implements OnInit{

  public form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      account: this.fb.group({
        accountNumber: [null, Validators.required],
        accountName: [null, Validators.required],
        accountType: [null, Validators.required],
        parentAccount: [null, Validators.required],
        description: [null, Validators.required]
      }),
      bank: this.fb.group({
        type: [null, Validators.required],
        routingName: [null, Validators.required],
        checkNumber: [null, Validators.required],
        accountBalance: [null, Validators.required]
      })
    })
  }

}
