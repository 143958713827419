<app-roam-dialog-type-one [title]="'Add Account'">
  <ng-container dialogContent>
    <div class="pt-10" [formGroup]="form">
      <div formGroupName="account">
        <div class="set-grid set-grid-2">
          <app-roam-input [name]="'Account Number'"
                          formControlName="accountNumber"
                          [ctrName]="'accountNumber'" [ctr]="form.controls['account'].get('accountNumber')"
          ></app-roam-input>
          <app-roam-input [name]="'Account Name'"
                          formControlName="accountNumber"
                          [ctrName]="'accountName'" [ctr]="form.controls['account'].get('accountName')"
          ></app-roam-input>
          <app-roam-select [placeholder]="'Select Account Type'"
                           formControlName="accountType"
                           [ctrName]="'accountName'" [ctr]="form.controls['account'].get('accountType')"
          ></app-roam-select>
          <app-roam-select [placeholder]="'Select Parent Account'"
                           formControlName="parentAccount"
                           [ctrName]="'parentAccount'" [ctr]="form.controls['account'].get('parentAccount')"
          ></app-roam-select>
        </div>
        <div class="mt-18">
          <app-roam-text-area [name]="'Account Description'"
                              formControlName="description"
                              [ctrName]="'description'"
                              [control]="form.controls['account'].get('description')"
          ></app-roam-text-area>
        </div>
      </div>
      <div class="mt-18">
        <app-roam-toggle-slider [label]="'Active Account'"
                                [labelClass]="'fw-500'"
                                [name]="'active'"
        ></app-roam-toggle-slider>
      </div>
      <hr>
      <h5 class="font-18px fw-500 tc-black">Bank Information</h5>
      <div class="set-grid set-grid-2 mt-18" formGroupName="bank">
        <app-roam-select [placeholder]="'Select Bank Detail Type'"
                         formControlName="type"
                         ctrName="type" [ctr]="form.controls['bank'].get('type')"
        ></app-roam-select>
        <app-roam-input [name]="'Bank Routing Number'"
                        formControlName="routingName"
                        ctrName="routingName" [ctr]="form.controls['bank'].get('routingName')"
        ></app-roam-input>
        <app-roam-input [name]="'Last Check Number'"
                        formControlName="checkNumber"
                        ctrName="checkNumber" [ctr]="form.controls['bank'].get('checkNumber')"
        ></app-roam-input>
        <app-roam-input [name]="'Account Balance'"
                        formControlName="accountBalance"
                        [currency]="true"
                        ctrName="accountBalance" [ctr]="form.controls['bank'].get('accountBalance')"
        ></app-roam-input>
      </div>
    </div>
  </ng-container>
</app-roam-dialog-type-one>
